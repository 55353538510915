const cards = [
  {
    title: 'Início',
    subtitle: '2o sábado de outubro, dia 9',
  },
  {
    title: 'Periodicidade',
    subtitle: 'Todo sábado, 16h',
  },
  {
    title: 'Onde',
    subtitle: 'Youtube e Facebook',
  },
]

export default cards
