const cards = [
  {
    title: 'Início',
    subtitle: '1o sábado de outubro, dia 2',
  },
  {
    title: 'Periodicidade',
    subtitle: 'Todo sábado, 12h',
  },
  {
    title: 'Onde',
    subtitle: 'Rua Martim Affonso, 152 - Belenzinho, São Paulo - SP, 03057-050',
  },
]

export default cards
