const classes: any[] = [
  {
    title: 'De Jerusalém a Babilônia',
    url: 'https://www.youtube.com/watch?v=lOdGMNEk9Uk',
  },
  {
    title: 'Médiuns versus Profeta',
    url: 'https://www.youtube.com/watch?v=OIsuz8P_iuU',
  },
]

export default classes
